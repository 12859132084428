import {
  CLOSE_NAVIGATION,
  OPEN_NAVIGATION,
  TOGGLE_NAVIGATION,
} from 'state/actions/types';

const navReducer = (state = false, action) => {
  switch (action.type) {
    case CLOSE_NAVIGATION:
      return false;
    case OPEN_NAVIGATION:
      return true;
    case TOGGLE_NAVIGATION:
      return !state;
    default:
      return state;
  }
};

export default navReducer;
