import { createStore as reduxCreateStore } from 'redux';

import reducers from 'state/reducers';

const initialState = {
  navigation: false,
};

const createStore = () => reduxCreateStore(reducers, initialState);
export default createStore;
